defineDs('Shared/Framework/Mithril/Scripts/Helpers/DOMUtils', [], function () {

  // Utilities

  // Toggle multiple event listeners on multiple elements
  var toggleEventListeners = function (toggleOn, $elements, eventTypes, callback, options) {
    $elements = (Array.isArray($elements) || $elements instanceof NodeList) ? $elements : [$elements];
    eventTypes = Array.isArray(eventTypes) ? eventTypes : [eventTypes];

    // Add/remove event handlers
    $elements.forEach(function ($element) {
      eventTypes.forEach(function (event) {
        if (toggleOn !== false) {
          $element.addEventListener(event, callback, options);
        } else {
          $element.removeEventListener(event, callback, options);
        }
      });
    });
  };

  // Remove multiple event listeners on multiple elements
  var removeEventListeners = function ($elements, eventTypes, callback, options) {
    toggleEventListeners(false, $elements, eventTypes, callback, options);
  };

  // Add multiple event listeners on multiple elements
  var addEventListeners = function ($elements, eventTypes, callback, options) {
    toggleEventListeners(true, $elements, eventTypes, callback, options);
  };

  // Return an object containing preconfigured .add() and .remove() methods
  var createEventListeners = function ($elements, eventTypes, callback, options) {
    // Declare guard boolean
    var isAdded = false;

    // Return listeners object
    return {
      add: function () {
        if (isAdded) {
          return;
        }
        addEventListeners($elements, eventTypes, callback, options);
        isAdded = true;
      },
      remove: function () {
        if (!isAdded) {
          return;
        }
        removeEventListeners($elements, eventTypes, callback, options);
        isAdded = false;
      }
    };
  };

  var calculateElementOuterHeight = ($element) => {
    if (!$element || Array.isArray($element)) {
      return;
    }

    var elemStyles = window.getComputedStyle($element);
    var elemMargins = parseFloat(elemStyles['marginTop']) + parseFloat(elemStyles['marginBottom']);

    return Math.ceil($element.offsetHeight + elemMargins);
  };

  var isElementDisplayed = function ($element) {
    return Boolean($element && ($element.offsetWidth || $element.offsetHeight || $element.getClientRects().length));
  };

  function ready(fn) {
    if (document.readyState !== 'loading') {
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }

  const getElementOffset = (element) => {
    if (!element) return { top: 0, left: 0 };
    const rect = element.getBoundingClientRect();
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft
    };
  };

  const calculateElementOuterWidth = (el) => {
    const style = window.getComputedStyle(el);
    const marginLeft = parseFloat(style.marginLeft);
    const marginRight = parseFloat(style.marginRight);
    return el.offsetWidth + marginLeft + marginRight;
  };

  return {
    removeEventListener,
    addEventListeners,
    createEventListeners,
    calculateElementOuterHeight,
    isElementDisplayed,
    getElementOffset,
    calculateElementOuterWidth,
    ready
  };
});
