defineDs('DanskeSpil/Domain/PermissionPopUp/Scripts/Components/ConsentPopUpSpot',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
    'Common/Framework/EventHandling/Scripts/AppEvent',
    'DanskeSpil/Domain/PermissionPopUp/Scripts/Helpers/ConsentApi',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],

  function (Component, Render, Storage, AppEvent, ConsentApi, Ensighten) {

    // Component:
    Component('ConsentPopUp', function (m, route, settings) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.loading = m.prop();
          this.consentPopUpSettings = m.prop();
          this.consentPopUpData = m.prop();
          this.consentsData = m.prop();
          this.dismissed = m.prop(true);
          this.storageName = m.prop('');
          this.atConfirm = m.prop(false);

          const formatConsentText = (text, textActions) => {
            textActions.forEach((action) => {
              const type = action.type?.toLowerCase();
              if (type === 'link') {
                text = text.replaceAll(action.highlight, `<a href="${action.content}">${action.highlight}</a>`);
              }
              if (type === 'popover') {
                text = text.replace(action.highlight, `<span class="consent-popup__tooltip" style="color: ${settings.actionTextColor}" data-label="${action.content}">${action.highlight}</span>`);
              }
            });
            return text;
          };

          // Functions:
          this.closeWindow = function (event) {
            var self = this;
            Storage.set(self.storageName(), true);
            this.dismissed(true);
            if (event.currentTarget.attributes.dataTracking) {
              Ensighten.pushGaEvent('Consent', event.currentTarget.attributes.datatracking.value, settings?.name);
            }
          }.bind(this);

          this.showConfirm = function () {
            var self = this;
            self.loading('true');
            self.setAccept();
            Storage.set(self.storageName(), true);
            self.loading('false');
            Ensighten.pushGaEvent('Consent', event.currentTarget.attributes.datatracking.value, settings?.name);
          }.bind(this);

          this.setAccept = function () {
            ConsentApi.updateConsent(this.consentsData()).then(function () {
              this.atConfirm(true);
              m.redraw();
            }.bind(this));
          }.bind(this);

          this.kvnNotVisible = function () {
            var $kvn = document.querySelector('.operations-messenger-content');
            return $kvn ? !getComputedStyle($kvn).display === 'none' : true;
          };

          this.storageName(settings.id);
          var storageValue = Storage.get(this.storageName()) || false;
          // Context:
          if (location.search.toLowerCase().indexOf('skipConsentPopUp') === -1) {
            if (settings) {
              this.consentPopUpSettings(settings);
              ConsentApi.getConsents().then(function (response) {
                // Index 0 is Consents for news. and index 1 is winner messeges.

                response[0].text = formatConsentText(response[0].text, response[0].textActions);
                this.consentPopUpData(response[0]);

                let consents = {
                  reference: settings?.name,
                  consentItems: []
                };

                const mergedConsents = [...response[0].mainConsents, ...response[0].subConsents];

                mergedConsents.forEach((consent) => {
                  if (consent.state.toLowerCase() != 'accepted') {
                    consents.consentItems.push({
                      id: consent.id,
                      accepted: true
                    });
                  }
                });

                this.consentsData(consents);
                if (this.consentPopUpData() && this.consentsData() && this.consentsData().consentItems.length > 0 && !storageValue) {
                  this.dismissed(false);
                  Ensighten.pushGaEvent('Consent', 'popup_shown', settings.name);
                }
                if (this.consentPopUpData() && this.consentsData() && settings.renderAsComponent) {
                  this.dismissed(false);
                  Ensighten.pushGaEvent('Consent', 'popup_shown', settings.name);
                }
                m.redraw();
              }.bind(this));
            }
          }

        },
        view: function (controller) {

          // Variables:
          var settings = controller.consentPopUpSettings();

          // View:
          if (settings && controller.kvnNotVisible() && controller.consentPopUpData()) {
            if (settings.renderAsComponent && (controller.consentsData().consentItems?.length === 0 || controller.dismissed() || controller.atConfirm())) {
              return m('div', { class: 'consent-popup__wrapper consent-popup_wrapper--show' }, m('div', { class: 'consent-popup__content', style: 'background-color: ' + settings.backgroundColor + '; ' + 'color: ' + settings.textColor }, [
                m('div', { class: 'consent-popup__element consent-popup__element--show' }, [
                  // content
                  m('div', m.trust(settings.receiptContent))
                ])
              ])
              );
            }
            return m('div', { class: 'consent-popup__wrapper ' + (controller.dismissed() ? 'consent-popup_wrapper--hide' : 'consent-popup_wrapper--show') }, [
              m('div', { class: 'consent-popup__shadow', dataTracking: 'popup_closed_background', onclick: controller.closeWindow }),
              m('div', { class: 'consent-popup__content', style: 'background-color: ' + settings.backgroundColor + '; ' + 'color: ' + settings.textColor }, [

                m('div', { class: 'consent-popup__element ' + (controller.atConfirm() ? 'consent-popup__element--hide' : 'consent-popup__element--show'), onmouseover: controller.mouseover }, [
                  settings.renderAsComponent ? '' : m('img.consent-popup__closebutton', { dataTracking: 'popup_closed_cross', onclick: controller.closeWindow, src: '/Components/DanskeSpil/Domain/PermissionPopUp/Graphics/SpriteSheets/icons.svg#close_use' }),
                  // content
                  m('div', [
                    m('h1', settings.headerText !== '' ? settings.headerText : controller.consentPopUpData().title),
                    m.trust(settings.additionalText),
                    m('div', { class: 'consent-popup__conent__text' }, [
                      m.trust(controller.consentPopUpData().text)
                    ]),
                  ]),
                  // confirm button
                  m('div', { class: 'consent-popup__cta-overflow', style: 'border-color: ' + settings.cancelButtonBorderColor }, [
                    m('a', { href: '#', class: 'consent-popup__cta', style: 'background-color: ' + settings.cancelButtonColor, dataTracking: 'popup_closed_yes', onclick: controller.showConfirm }, [
                      m('div', { class: 'consent-popup__cta-container' }, [
                        (controller.loading()) ?
                          m('div', { class: 'consent-popup__cta-loader-box' }, [
                            m('span', { class: 'loader-animation-box' }, [
                              m('span', { class: 'loader-dot' }),
                              m('span', { class: 'loader-dot' }),
                              m('span', { class: 'loader-dot' })
                            ])
                          ]) : null,
                        m.trust(settings.cancelButtonContent),
                      ])
                    ]),

                  ]),
                  // cancel button
                  m('div', { class: 'consent-popup__cta-overflow', style: 'border-color: ' + settings.confirmButtonBorderColor }, [
                    m('a', { href: '#', class: 'consent-popup__cta', style: 'background-color: ' + settings.confirmButtonColor, dataTracking: 'popup_closed_no', onclick: controller.closeWindow }, [
                      m('div', { class: 'consent-popup__cta-container' }, [
                        m.trust(settings.confirmButtonContent),
                      ]),
                    ]),
                  ]),
                ]),
                // Confirm window
                m('div', { class: 'consent-popup__element ' + (!controller.atConfirm() ? 'consent-popup__element--hide' : 'consent-popup__element--show'), onmouseover: controller.mouseover }, [
                  m('img.consent-popup__closebutton', { onclick: controller.closeWindow, src: '/Components/DanskeSpil/Domain/PermissionPopUp/Graphics/SpriteSheets/icons.svg#close_use' }),
                  // content
                  m('div', m.trust(settings.receiptContent)),
                  // button 1
                  m('div', { class: 'consent-popup__cta-overflow', style: 'border-color: ' + settings.closeReceiptButtonBorderColor }, [
                    m('a', { href: '#', class: 'consent-popup__cta', style: 'background-color: ' + settings.closeReceiptButtonColor, onclick: controller.closeWindow }, [
                      m('div', { class: 'consent-popup__cta-container' }, [
                        m.trust(settings.closeReceiptButtonContent),
                      ])
                    ])
                  ])
                ])
              ])
            ]);
          }
        }

      };

      // Routes:
      route('/', root);

    });

  });
