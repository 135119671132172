defineDs('DanskeSpil/Domain/PlayerAccountManagement/Scripts/Components/Widgets/QuickOverlay',
  [
    'Shared/Framework/Ensighten/Scripts/Ensighten',
    'Common/Framework/EventHandling/Scripts/CrossWindowEvents'
  ],
  function (Ensighten, CrossWindowEvents) {

    const $element = document.querySelector('[data-component="pam-quick-overlay"]');

    if (!$element) {
      return;
    }

    let settings = $element.getAttribute('data-settings') || {};

    if (typeof settings === 'string') {
      settings = JSON.parse(settings);
    }

    const $iframe = $element.querySelector('iframe');
    const $closeButton = $element.querySelector('.js-pam-quick-overlay__close-button');
    const $backgroundElem = $element.querySelector('.js-pam-quick-overlay__blurred-background');

    if ($closeButton) {
      $closeButton.addEventListener('click', () => {
        $element.classList.add('pam-quick-overlay--hidden');
      });
    }

    if ($backgroundElem) {
      $backgroundElem.addEventListener('click', () => {
        $element.classList.add('pam-quick-overlay--hidden');
      });
    }

    const iframeLoaded = () => {
      $element.classList.remove('pam-quick-overlay--hidden');

      window.addEventListener('message', (ev) => {
        if (ev.origin !== window.location.origin) {
          return;
        }

        if (ev.data === 'component-async-data-loaded') {
          setTimeout(() => {
            setIframeHeight();
          }, 500);
        }
      });
    };

    const setIframeHeight = () => {
      const $pageArea = document.querySelector('.page-area');
      const manuallySetMarginTop = 20;
      const iframeContentHeight = $iframe.contentWindow.document.body.scrollHeight + manuallySetMarginTop;
      $iframe.style.height = iframeContentHeight + 'px';

      if ($pageArea && iframeContentHeight) {
        $pageArea.style.minHeight = iframeContentHeight + 'px';
      }
    };

    const initialize = () => {
      $iframe.addEventListener('load', iframeLoaded);
      Ensighten.pushGaEvent('PAM', 'QuickOverlay', 'Shown');
    };

    if (/loaded|interactive|complete/.test(document.readyState)) {
      initialize();
    } else {
      window.addEventListener('DOMContentLoaded', initialize, false);
    }

    CrossWindowEvents.subscribe('ds.event.userLoggedOut', () => {
      $element.remove();
    });
  });
