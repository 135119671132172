defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/Components/Navigation', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/Feature.Navigation/Scripts/Helpers/Debounce',
], function (Utils, Debounce) {
  const navigation = document.querySelector('.js-navigation');

  if (!navigation) {
    return;
  }

  let innerWidth = window.innerWidth;

  const resetNavigation = () => {
    document.dispatchEvent(new CustomEvent('navigation:scroll:show'));
    document.dispatchEvent(new CustomEvent('navigation:mainMenu:closeAllSubMenus'));
    document.dispatchEvent(new CustomEvent('navigation:chooseGames:close'));
    document.dispatchEvent(new CustomEvent('navigation:burgerMenu:close'));
    document.dispatchEvent(new CustomEvent('navigation:accountMenu:close'));
    document.dispatchEvent(new CustomEvent('responsible-gaming:close'));
  };

  const showNavigation = () => {
    navigation.classList.remove('hide');
    resetNavigation();
  };

  const hideNavigation = () => {
    navigation.classList.add('hide');
  };

  const saveCurrentBrandURL = () => {
    if (
      document.querySelector('body.region-player-account-management')
      || !document.querySelector('.js-navigation')
      || document.querySelector('.top-navigation') // handled in HeaderNavigation.js
    ) {
      return;
    }

    const returnUrl = Utils.getParameter('returnUrl');

    // Ported URL-filter from HeaderNavigation.js
    // Fixes Login-1044
    if (returnUrl && returnUrl.includes('postlogin')) {
      return;
    }

    const prefixedKey = `${DS.Config.CONTEXT}currentBrandURL`;
    const value = document.location.pathname + document.location.search;

    const cookieSettings = { expires: 7, path: '/' };
    Utils.cookie(prefixedKey, value || '', cookieSettings);
  };

  const initialize = () => {
    saveCurrentBrandURL();

    document.addEventListener('navigation:reset', resetNavigation);
    document.addEventListener('navigation:hide', hideNavigation);
    document.addEventListener('navigation:show', showNavigation);

    // Handle scroll
    window.addEventListener('scroll', Debounce(() => {
      document.dispatchEvent(new CustomEvent('navigation:scroll'));
    }, 10));

    // Handle screen rotation or resize
    window.addEventListener('resize', Debounce(() => {
      // Samsung mobile devices (at least on Chrome) triggers a resize event, in the height, when scrolling inside ResponsibleGaming menu.
      // In any case, we don't need to do worry about closing the open side menus, unless the width of the window changes.
      if (innerWidth !== window.innerWidth) {
        resetNavigation();
        innerWidth = window.innerWidth;
      }
    }, 500));

    // Close modal or reset navigation when pressing the Escape key
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        const hasModalOpen = document.querySelector('.js-modal-popup.open');

        if (hasModalOpen) {
          document.dispatchEvent(new CustomEvent('modal:close'));
        } else {
          resetNavigation();
        }
      }
    });

  };

  if (navigation) {
    initialize();
  }

});


