defineDs('DanskeSpil/Domain/Header/Scripts/Components/OperationsMessenger', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents'
], function (Component, Storage, ApiRequest, CrossWindowEvents) {
  /** ********************************************************************
   * Request the message server with interval and find out if a operations
   * message must be shown on the current page in an overlay. The response
   * is stored in local storage and only delta is requested.
   */
  var messageData = null;
  var messageToShow = null;
  var storageKey = 'operationsMessages';
  var currentContext = {};
  var requestIntervalMillis = 15 * 1000;
  var dataMaxLifeMillis = 12 * 60 * 60 * 1000;
  var storageLifetimeSeconds = 12 * 60 * 60;
  var emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.[a-zA-z]{2,}$/;
  var smsRegex = /^\s*(\+\d{2,3})*(\s*\d){6,20}\s*$/;
  var subscribeStatus = 'input';
  var mapLogoColor = null;
  var assets = null;
  var tracked = false;
  var mapBrand = [
    // Brands:
    { className: 'region-avalon-allornothing', brand: 'AltEllerIntet' },
    { className: 'region-avalon-bingo', brand: 'Bingo' },
    { className: 'region-avalon-bingo-gvc', brand: 'Bingo' },
    { className: 'region-avalon-casino', brand: 'Casino' },
    { className: 'region-avalon-dantoto', brand: 'Dantoto' },
    { className: 'region-avalon-eurojackpot', brand: 'Eurojackpot' },
    { className: 'region-avalon-keno', brand: 'Keno' },
    { className: 'region-avalon-klasselotteriet', brand: 'Klasselotteriet' },
    { className: 'region-avalon-livecasino', brand: 'LiveCasino' },
    { className: 'region-avalon-lotto', brand: 'Lotto' },
    { className: 'region-avalon-oddset', brand: 'Oddset' },
    { className: 'region-avalon-poker', brand: 'Poker' },
    { className: 'region-avalon-quick', brand: 'Quick' },
    { className: 'region-avalon-spillehjoernet', brand: 'Spillehjornet' },
    { className: 'region-avalon-tips', brand: 'Tips' },
    { className: 'region-avalon-vikinglotto', brand: 'Vikinglotto' },
    { className: 'region-avalon-playtogether', brand: 'SpilSammen' },
    { className: 'region-casino', brand: 'Casino' },
    { className: 'region-dantoto', brand: 'Dantoto' },
    { className: 'region-eurojackpot', brand: 'Eurojackpot' },
    { className: 'region-keno', brand: 'Keno' },
    { className: 'region-lotto', brand: 'Lotto' },
    { className: 'region-oddset', brand: 'Oddset' },
    { className: 'region-eoddset', brand: 'eOddset' },
    { className: 'region-poker', brand: 'Poker' },
    { className: 'region-subscriptions', brand: 'Plus' },
    { className: 'region-tips', brand: 'Tips' },
    { className: 'region-vikinglotto', brand: 'Vikinglotto' },
    { className: 'region-playtogether', brand: 'SpilSammen' },
    { className: 'region-allornothing', brand: 'AltEllerIntet' },
    { className: 'region-casino-web', brand: 'Casino' },

    // Features:
    { className: 'region-retail-account', feature: 'Sikkert_Spil' }
  ];

  Component('operations-messenger', [], function (m, route) {
    var dictionary = {
      toBeInitialised: true,
      CloseButton: 'LUK BESKEDEN' // backup text in case there is any problem
    };

    var d = function (key) {
      return (dictionary[key] ? dictionary[key] : '');
    };


    /** ********************************************************************
     * At page load, check if we have data in local storage or else make a
     * request.
     * If we already have data, see if the data is still valid or if we
     * need to make an update. If the first request is older than
     * dataMaxLifeMillis, we make a new request.
     */
    var getMessageData = function () {
      messageData = JSON.parse(Storage.get(storageKey));
      if (messageData == null || getDataLifeTime() > dataMaxLifeMillis) {
        requestMessages();
        setTimeout(updateAndStartInterval, requestIntervalMillis);
      } else { // we have data
        var nextUpdateMillis = getNextUpdateMillis();
        if (nextUpdateMillis <= 0) {
          requestMessages();
          setInterval(requestMessages, requestIntervalMillis);
        } else { // data is still valid and we can update later
          setTimeout(updateAndStartInterval, nextUpdateMillis);
        }
      }
    };

    /** ********************************************************************
     * Get the milliseconds since first request or default 24h.
     */
    var getDataLifeTime = function () {
      if (!messageData.firstRequest) {
        return 24 * 60 * 60 * 1000;
      }
      var now = new Date().getTime();
      var firstRequest = new Date(messageData.firstRequest).getTime();
      return firstRequest - now;
    };

    /** ********************************************************************
     * Get the number of millisecomd until the data must be updated.
     */
    var getNextUpdateMillis = function () {
      if (messageData && messageData.lastUpdate) {
        var now = new Date().getTime();
        var lastUpdate = new Date(messageData.lastUpdate).getTime();
        return lastUpdate + requestIntervalMillis - now;
      }
      return 0;
    };

    /** ********************************************************************
     * Do an data update request now and repeat every requestIntervalMillis.
     */
    var updateAndStartInterval = function () {
      requestMessages();
      setInterval(requestMessages, requestIntervalMillis);
    };

    /** ********************************************************************
     * Request /api/client/messages, set the request time, store and redraw.
     */
    var requestMessages = function () {
      ApiRequest({
        url: DS.Config.OPERATIONS_MESSENGER_API_URL + '/api/client/messages?platform=Sitecore',
        logging: false,
        noCache: false
      }).then(function (response) {
        setTimeout(function () { // wait for cookie popup to show
          if (!messageData && isCookiePopupVisible()) { // if we dont already have messages
            setTimeout(requestMessages, requestIntervalMillis);
            return; // if cookie popup is visible, make a new request later
          }

          var newMessageData = response;

          // Null-check before manipulating
          if (!messageData) {
            messageData = newMessageData;
          } else {
            var i;
            // Check for message updates
            for (i = 0; i < newMessageData.messages.length; i++) {
              updateMessage(newMessageData.messages[i]);
            }

            // Check for archived messages
            for (i = 0; i < messageData.messages.length; i++) {
              var shouldBeDeleted = true;
              for (var j = 0; j < newMessageData.messages.length; j++) {
                if (newMessageData.messages[j].id === messageData.messages[i].id) {
                  shouldBeDeleted = false;
                }
              }

              if (shouldBeDeleted) {
                removeMessage(messageData.messages[i].id);
              }
            }
          }

          messageData.lastUpdate = new Date();
          messageData.firstRequest = new Date();
          messageData.updatesToken = response.updatesToken;
          storeData(messageData);
          messageToShow = anyMessageToShow();

          if (messageToShow) {
            requestTexts();
          }

          m.redraw(false);
        }, 1000); // 1 sec
      });
    };

    var isCookiePopupVisible = function () {
      const popup = document.querySelector('#ensModalWrapper');
      return popup && window.getComputedStyle(popup).display !== 'none';
    };

    var postUserView = function (id) {
      ApiRequest({
        method: 'POST',
        data: {
          platform: 'Sitecore'
        },
        url: DS.Config.OPERATIONS_MESSENGER_API_URL + '/api/client/messages/' + id + '/userView'
      });
    };

    var postUserInteraction = function (id) {
      ApiRequest({
        method: 'POST',
        data: {
          platform: 'Sitecore'
        },
        url: DS.Config.OPERATIONS_MESSENGER_API_URL + '/api/client/messages/' + id + '/userInteraction'
      });
    };

    /** ********************************************************************
     * Load dictionary texts json in the form:
     * { OperationsMessenger: { Key:"value", ... } }
     */
    var requestTexts = function () {
      if (dictionary.toBeInitialised && isUrlConfigured()) {
        getAssetsPath(function () {
          ApiRequest({
            url: assets.assetsUrl + assets.dictionary,
            localCacheTTL: 300,
            logging: false
          }).then(function (response) {
            dictionary = response.OperationsMessenger;
            m.redraw(false);
          });
        });
      }
    };

    /** ********************************************************************
     * Load colors and logos json in the form:
     * { "Lotto": { backgroundColor: '#c50005', textColor: '#ffffff', logo: '/gfx/brands/lotto.png' } }
     */
    var getBrandColors = function () {
      getAssetsPath(function () {
        ApiRequest({
          url: assets.assetsUrl + assets.brandUISettings,
          localCacheTTL: 300,
          logging: false
        }).then(function (response) {
          mapLogoColor = response;
          m.redraw();
        });
      });
    };

    /** ********************************************************************
     * Load json in the form:
     * { assetsUrl: "http://baseUrl", dictionary: "/path", brandUISettings: "/path" }
     */
    var getAssetsPath = function (done) {
      if (assets) {
        invoke(done);
      } else {
        ApiRequest({
          url: DS.Config.OPERATIONS_MESSENGER_API_URL + '/api/client/assets',
          localCacheTTL: 300,
          logging: false
        }).then(function (response) {
          assets = response;
          invoke(done);
        });
      }
    };

    var invoke = function (done) {
      if (typeof done === 'function') {
        done();
      }
    };

    var postSubscriber = function (message, address) {
      subscribeStatus = 'sent';
      var type = 'Sms';
      if (emailRegex.test(address)) {
        type = 'Email';
      }
      ApiRequest({
        method: 'POST',
        url: DS.Config.OPERATIONS_MESSENGER_API_URL + '/api/client/messages/' + message.id + '/notification/subscriber',
        data: {
          notificationType: type, // we may need to distinguish type
          subscriberName: '',
          subscriberAddress: address,
          platformUrl: '',
          platform: 'Sitecore'
        }
      }).then(function () {
        message.subscribed = true;
        updateMessage(message);
        storeData();
        subscribeStatus = 'subscribed';
        m.redraw(false);
      }, function () {
        subscribeStatus = 'error';
        m.redraw(false);
      });
    };

    var deleteSubscriber = function (message, address) {
      ApiRequest({
        method: 'DELETE',
        url: DS.Config.OPERATIONS_MESSENGER_API_URL + '/api/client/messages/' + message.id + '/notification/subscriber',
        data: {
          subscriberAddress: address,
        }
      }).then(function () {
        message.subscribed = false;
        subscribeStatus = 'unsubscribed';
        updateMessage(message);
        storeData();
        m.redraw(false);
      }, function () {
        subscribeStatus = 'error';
        m.redraw(false);
      });
    };

    var storeData = function () {
      Storage.set(storageKey, JSON.stringify(messageData), storageLifetimeSeconds);
    };

    var updateMessage = function (message) {
      var shouldBeAdded = true;

      for (var i = 0; i < messageData.messages.length; i++) {
        if (messageData.messages[i].id === message.id) {
          // Update messageContent
          var messageToUpdate = messageData.messages[i];
          messageToUpdate.heading = message.heading;
          messageToUpdate.messageText = message.messageText;
          messageToUpdate.plugin = message.plugin;
          messageToUpdate.contexts = message.contexts;
          messageToUpdate.version = message.version;
          shouldBeAdded = false;
        }
      }

      if (shouldBeAdded) {
        messageData.messages.push(message);
      }
    };

    var removeMessage = function (id) {
      for (var i = 0; i < messageData.messages.length; i++) {
        if (messageData.messages[i].id === id) {
          messageData.messages.splice(i, 1);
        }
      }
    };

    /** ********************************************************************
     * Notify the server that the message has been shown and update local
     * storage.
     */
    var registerShow = function (message) {
      postUserView(message.id);
      message.showed = true;
      updateMessage(message);
      storeData();
    };

    var verifySubscription = function (input) {
      return (emailRegex.test(input) || smsRegex.test(input));
    };

    /** ********************************************************************
     * Get one message to show according to currentContext or undefined.
     */
    var anyMessageToShow = function () {
      if (messageData && messageData.messages) {
        for (var i = 0; i < messageData.messages.length; i++) {
          var message = messageData.messages[i];
          if (message.contexts) {
            for (var j = 0; j < message.contexts.length; j++) {
              var c = message.contexts[j];
              if (c.context === 'Brand') {
                if (c.brand === currentContext.brand) {
                  message.heading = c.heading;
                  message.messageText = c.messageText;
                  return message;
                }
              }
              if (c.context === 'Feature') {
                if (c.feature === currentContext.feature) {
                  message.heading = c.heading;
                  message.messageText = c.messageText;
                  return message;
                }
              }
              if (c.context === currentContext.context) {
                message.heading = c.heading;
                message.messageText = c.messageText;
                return message;
              }
            }
          }
        }
      }
    };

    /** ********************************************************************
     * Get the brand and feature for this page if any and map to name.
     */
    var getCurrentContext = function () {
      var bodyClasses = document.body.classList;
      var context = { brand: '', context: '', feature: '' };

      for (var i = 0; i < mapBrand.length; i++) {
        if (bodyClasses.contains(mapBrand[i].className)) {
          context.brand = mapBrand[i].brand;
          context.feature = mapBrand[i].feature;

          // HACK: Since eoddset and oddset are on the same site we need this (IU-15415)
          if (context.brand === 'Oddset' && location.href.includes('esport')) {
            context.brand = 'eOddset';
          }
        }
      }


      // HACK: Since the oddset update pages are just regular oddset pages, check for url for settings feature (IU-19276)
      if (bodyClasses.contains('region-avalon-oddset') && location.href.toLowerCase().includes('opdatering')) {
        context.feature = 'Oddset_Update';
      }

      if (bodyClasses.contains('region-player-account-management')) {
        context.context = 'PAM_' + DS.Config.CONTEXT;
      }
      if (bodyClasses.contains('Payment')) {
        context.feature = 'Indbetaling';
      }
      if (bodyClasses.contains('PayOut')) {
        context.feature = 'Udbetaling';
      }

      return context;
    };

    var getBrandLogoPath = function (brand) {
      if (mapLogoColor[brand]) {
        return assets.assetsUrl + mapLogoColor[brand].logo;
      }
      return '';
    };

    var getBrandBackgroundColor = function (brand) {
      if (mapLogoColor[brand]) {
        return mapLogoColor[brand].backgroundColor;
      }
      return '#666';
    };

    var getBrandTextColor = function (brand) {
      if (mapLogoColor[brand]) {
        return mapLogoColor[brand].textColor;
      }
      return '#fff';
    };

    var isUrlConfigured = function () {
      return (DS.Config.OPERATIONS_MESSENGER_API_URL &&
        DS.Config.OPERATIONS_MESSENGER_API_URL.charAt(0) != '#' &&
        DS.Config.OPERATIONS_MESSENGER_API_URL.indexOf('OPERATIONS_MESSENGER_API_URL') == -1);
    };

    var unsubscribeLink = function () {
      return m('div', { class: 'unsubscribe-link-text' }, [
        d('UnsubscribeText') + ' ',
        m('a', {
          href: '#',
          class: 'unsubscribe-link',
          onclick: function () {
            subscribeStatus = 'unsubscribe';
          }
        }, d('UnsubscribeLinkText'))
      ]);
    };

    var root = {

      controller: function () {
        this.validationError = m.prop(false);
        this.inputValue = '';
        this.validationTimer;
        this.isFirstDraw = true;
        var self = this;

        this.trackViewed = function () {
          if (!tracked) {
            tracked = true;
            if (window.dataLayer) {
              window.dataLayer.push({
                event: 'gaEvent',
                eventSyntax:
                {
                  action: 'kvn_vist',
                  category: 'errorMessage',
                  label: messageToShow.heading
                }
              });
            }
          }
        };

        this.showMessage = function (message, visible) {
          message.notVisible = !visible;
          updateMessage(message);
          storeData();
        };

        this.slideInClass = function () {
          if (self.isFirstDraw) {
            self.isFirstDraw = false;
            return ' slide-in';
          }
          return '';
        };

        this.onInput = function (e) {
          self.inputValue = e.target.value;
          self.validationError(false);
          if (self.validationTimer) {
            clearTimeout(self.validationTimer);
          }
          self.validationTimer = setTimeout(function () {
            self.validationError(self.inputValue !== '' && !verifySubscription(self.inputValue));
            m.redraw();
          }, 1000);
        };

        this.getAnyPlugin = function (message, ctrl) {
          if (message && message.plugin) {
            if (message.plugin.type === 'BrandReference') {
              if (mapLogoColor) {
                return m('div', {
                  class: 'operations-messenger-brand-plugin ' + message.plugin.brand,
                  onclick: function () {
                    postUserInteraction(message.id);
                    window.location.href = message.plugin.referenceUrl;
                  },
                  style: 'background-color: ' + getBrandBackgroundColor(message.plugin.brand) + '; color: ' + getBrandTextColor(message.plugin.brand) + '; '
                }, [
                  m('p', d('BrandReference')),
                  m('img', {
                    src: getBrandLogoPath(message.plugin.brand),
                    class: 'brand-logo',
                    alt: message.plugin.brand
                  }),
                  m('div', { class: 'arrow' })
                ]);
              } else {
                getBrandColors();
              }
            } else if (message.plugin.type === 'NotificationAfterClose') { // subscribe/unsubscribe
              var isUnsubscribe = (subscribeStatus == 'unsubscribe');
              if (subscribeStatus == 'input' || isUnsubscribe) {
                if (!d('UnsubscribeButtonText') || !d('SubscriptionButton')) {
                  // Return if texts are missing
                  return;
                }
                return [
                  m('div', { class: 'operations-messenger-notification-plugin' }, [
                    m('h3', isUnsubscribe ? d('UnsubscribeHeadline') : d('SubscriptionText')),
                    isUnsubscribe ? m('p', d('UnsubscribeBodyText')) : null,
                    m('form', {
                      class: 'operations-messenger-notification-plugin__content',
                      onsubmit: function (e) {
                        e.preventDefault();
                        if (verifySubscription(ctrl.inputValue)) {
                          if (isUnsubscribe) {
                            deleteSubscriber(message, ctrl.inputValue);
                          } else {
                            postSubscriber(message, ctrl.inputValue);
                            postUserInteraction(message.id);

                            if (window.dataLayer) {
                              window.dataLayer.push({
                                event: 'gaEvent',
                                eventSyntax:
                                {
                                  action:
                                    'kvn_tilmeldt_' + (emailRegex.test(ctrl.inputValue) ? 'email' : 'sms'),
                                  category: 'errorMessage',
                                  label: message.heading
                                }
                              });
                            }
                          }
                        }
                      }
                    }, [
                      m('input', {
                        placeholder: d('SubscriptionPlaceholder'),
                        oninput: ctrl.onInput
                      }, ''),
                      m('button', { type: 'submit' }, isUnsubscribe ? d('UnsubscribeButtonText') : d('SubscriptionButton'))]),
                    ctrl.validationError() ? m('p', { class: 'validation-message' }, d('SubscriptionValidationError')) : null
                  ]),
                  unsubscribeLink()
                ];
              } else {
                return [ // subscribe/unsubscribe receipt
                  m('div', { class: 'operations-messenger-notification-subscribed' }, [
                    (function () {
                      switch (subscribeStatus) {
                      case 'subscribed':
                        return m('p', m.trust(d('SubscriptionReceipt')));
                      case 'error':
                        return m('p', m.trust(d('SubscriptionError')));
                      case 'unsubscribed':
                        return [
                          m('h3', m.trust(d('UnsubscribeHeadline'))),
                          m('p', m.trust(d('UnsubscribeReceiptText')))
                        ];
                      }
                      return null;
                    })()
                  ]),
                  unsubscribeLink()
                ];
              }
            }
          }
        };

        if (isUrlConfigured()) {
          if (messageData == null) {
            getMessageData();
            currentContext = getCurrentContext();
          }

          CrossWindowEvents.subscribe('ds.event.loadedWidget', function () {
            currentContext = getCurrentContext();
            m.redraw();
          });

          messageToShow = anyMessageToShow();
          if (messageToShow) {
            requestTexts();
          }
        }
      },

      view: function (ctrl) {
        if (messageToShow) {

          ctrl.trackViewed();

          var plugin = ctrl.getAnyPlugin(messageToShow, ctrl);

          // Early return if plugin needs to be initialised
          if (messageToShow.plugin && !plugin) {
            return;
          }

          // Early return if dictionary needs initialisation
          if (dictionary.toBeInitialised) {
            return;
          }

          if (!messageToShow.showed) {
            registerShow(messageToShow);
          }

          if (messageToShow.priority === 2) {
            return m('div', { class: messageToShow.notVisible ? '' : 'visible' }, [
              m('div', { class: 'dark-background' }),
              m('div', { class: 'operations-messenger-wrapper' }, [
                m('div', { class: 'operations-messenger-content-alternate' + ctrl.slideInClass() }, [
                  m('div', { class: 'icon-circle' }, [
                    m('div', { class: 'icon' })
                  ]),
                  m('h3', { class: 'operations-messenger-heading' }, messageToShow.heading),
                  m('div', {
                    class: 'operations-messenger-button-close',
                    onclick: function () {
                      ctrl.showMessage(messageToShow, false);
                    }
                  }, d('CloseButton'))
                ])
              ]),

            ]);
          } else {
            return m('div', { class: messageToShow.notVisible ? '' : 'visible' }, [
              m('div', { class: 'dark-background' }),
              m('div', { class: 'operations-messenger-wrapper' }, [
                m('div', { class: 'operations-messenger-content' + ctrl.slideInClass() }, [
                  m('div', { class: 'top-bar' }),
                  m('div', { class: 'icon-circle' }, [
                    m('div', { class: 'icon' })
                  ]),
                  m('h3', { class: 'operations-messenger-heading' }, messageToShow.heading),
                  m('p', { class: 'operations-messenger-text' }, messageToShow.messageText),
                  plugin,
                  m('div', {
                    class: 'operations-messenger-button-close',
                    onclick: function () {
                      ctrl.showMessage(messageToShow, false);
                    }
                  }, d('CloseButton'))
                ])
              ]),
              m('div', {
                class: 'operations-messenger-button',
                onclick: function () {
                  ctrl.isFirstDraw = true;
                  if (subscribeStatus == 'unsubscribe' || subscribeStatus == 'unsubscribed') {
                    subscribeStatus = 'input';
                  }
                  ctrl.showMessage(messageToShow, true);
                }
              }, [
                m('div', { class: 'icon' })
              ])
            ]);
          }
        }
      }
    };

    route('/', root);
  });
});
