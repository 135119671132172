defineDs('Shared/Framework/DsApi/Scripts/DsApiParent', [
  'DanskeSpil/Domain/Authentification/Scripts/LoginController',
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/Authentification/Scripts/SitecoreAuth',
  'DanskeSpil/Project/Design/Js/Global/HashChange',
], function (LoginController, CrossWindowEvents, Event, SitecoreAuth, HashChange) {

  window.DS = window.DS || {};

  DS.apiParent = {

    /* ### FOR DSAPI ACCOUNT ### */

    /* Master callback events */
    _userLoggedOutCallbacks: [],
    onUserLoggedOut: function () {
      DS.apiParent.runCallbackCue(DS.apiParent._userLoggedOutCallbacks);
    },

    _userLoggedInCallbacks: [],
    onUserLoggedIn: function () {
      DS.apiParent.runCallbackCue(DS.apiParent._userLoggedInCallbacks);
    },

    _balanceUpdatedCallbacks: [],
    onBalanceUpdated: function () {
      DS.apiParent.runCallbackCue(DS.apiParent._userLoggedInCallbacks);
    },

    /* Helper */
    runCallbackCue: function (callbacks) {
      if (callbacks) {
        callbacks.forEach(function (c) {
          c();
        });
      }
    },


    /* Functions called from DSAPI */

    subscribeUserLoggedOut: function (callback) {
      DS.apiParent._userLoggedOutCallbacks = [callback];
    },

    subscribeUserLoggedIn: function (callback) {
      DS.apiParent._userLoggedInCallbacks = [callback];
    },

    subscribeBalanceUpdated: function (callback) {
      DS.apiParent._balanceUpdatedCallbacks = [callback];
    },

    openLogin: function (params) {
      params = params || {};
      var openLoginOptions = {};
      if (params.onCancel) {
        openLoginOptions.onCancel = params.onCancel;
      }
      if (params.cancelUrl) {
        openLoginOptions.cancelUrl = params.cancelUrl;
      }

      LoginController.openLogin(openLoginOptions);

    },

    logout: function () {
      LoginController.logout();
    },

    openMyPage: function () {
      console.warn('openMyPage Deprecated');
    },

    openPayment: function (params) {
      /* As per 2022-06-09 this is still in use on (based on Sentry tracking):
       *
       * https://danskespil.dk/dantoto/spil-nu-side
       * https://danskespil.dk/spil-sammen/opret-kupon/bekraeft
       * https://danskespil.dk/tips/tips13
       * https://danskespil.dk/tips/tips12
       * https://danskespil.dk/tips
       * https://danskespil.dk/oddset/maljagt

       */

      params = params || {};
      var returnUrl = encodeURIComponent(params.returnUrl || location.href);
      window.location.href = location.protocol + '//' + location.hostname + DS.Config.PRETTYPREFIX + '/indbetaling?source=dsapi&returnurl=' + returnUrl + (params.instantAmount ? '&instantAmount=' + params.instantAmount : '');
    },

    doBalanceUpdate: function () {
      CrossWindowEvents.fire('ds.event.balanceUpdated', { forceUpdate: true });
    },

    getBalance: function (params) {
      params = params || {};

      /* Sportech uses this */
      SitecoreAuth.getUserObj(function (response) {
        if (response && response.customerInfo) {
          params.onSuccess && params.onSuccess({ balance: response.customerInfo.balance });
        } else {
          params.onError && params.onError();
        }
      });
    },

    noUserAlias: function () {
      var returnUrl = encodeURIComponent(location.href);
      window.location.href = DS.Config.PRETTYPREFIX + '/mine-oplysninger?returnUrl=' + returnUrl + '#/alias';
    },

    pushToDataLayer: function (e) {
      if (window.dataLayer) {
        window.dataLayer.push(e);
      } else {
        console.debug('Could not add to dataLayer', e);
      }
    },

    /* ### FOR DSAPI PARENT FRAME ### */
    _hashChangeCallbacks: [],

    updateGameFrameHeight: function (params) {
      Event.fire('dsapi-update-game-frame-height', params || {});
    },

    setParentHash: function (params) {
      /* Used in OpenBet (phase 2) */
      if (params.hash) {
        HashChange.setHashFromDsapi(params.hash);
      }
    },

    subscribeParentHashChanged: function (params) {
      /* Used in OpenBet (phase 2) */
      if (params.callback) {
        DS.apiParent._hashChangeCallbacks.push(params.callback);
      }

      // Right after subscribe, run onHashChanged callback, in order to get init hash
      var gameVerticalHashMatcher = HashChange.getGameVerticalHashMatcher();

      if (gameVerticalHashMatcher && gameVerticalHashMatcher(location.hash)) {
        params.callback(location.hash);
      }
    },

    onHashChanged: function () {
      var self = DS.apiParent;

      console.debug('ds apiparent onHashChanged');

      var gameVerticalHashMatcher = HashChange.getGameVerticalHashMatcher();

      if (gameVerticalHashMatcher && gameVerticalHashMatcher(location.hash) && self._hashChangeCallbacks.length > 0) {
        console.debug('  match and callback found');
        self._hashChangeCallbacks.forEach(function (c) {
          console.log(c);

          c(location.hash);
        });
      }
    },

    setUrl: function (url) {
      window.location.href = url;
    },

    init: function () {
      Event.subscribe('dsapi-on-parent-hash-changed', function () {
        DS.apiParent.onHashChanged();
      });

    }
  };

  // Init:
  DS.apiParent.init();

});
