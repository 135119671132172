defineDs('DanskeSpil/Domain/PermissionPopUp/Scripts/Helpers/ConsentApi', [
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest'
], function (ApiRequest) {

  // NOTICE: This is a copy of DanskeSpil/Domain/PlayerAccountManagement/Notifications/Scripts/Data/NotificationsApi
  // with the difference, that this version is using ApiRequest instead Request module.
  // The reason for this is that ApiRequest has some PAM script dependencies, which we wont include
  // on all other pages (PermissionPopupSpot is a global functionality).

  var getConsents = function () {
    return ApiRequest({
      method: 'GET',
      url: DS.Config.CONSENT_API_URL + '/v1/consent',
      background: true
    });
  };

  var updateConsent = function (Permission) {
    return ApiRequest({
      method: 'POST',
      data: Permission,
      url: DS.Config.CONSENT_API_URL + '/v2/consent',
      background: true
    });
  };

  return {
    getConsents: getConsents,
    updateConsent: updateConsent
  };
});
