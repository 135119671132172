defineDs('DanskeSpil/Domain/Navigation/Scripts/GenericSmartBannerDetection', [
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils',
], function (Storage, Ensighten, DOMUtils) {

  const $smartbanner = document.querySelector('.generic-smartbanner');
  const $staticSmartbannerFix = document.querySelector('.generic-smartbanner-static-fix');

  if ($smartbanner?.dataset['cookie-name']) {
    var region = $smartbanner?.dataset['cookie-name'].toLowerCase();
  }

  var detect = function () {
    // Get localstorage Expiry date
    var regionCheck = region + '-smartbanner';
    // var cookieExpiryDate = localStorage.getItem(regionCheck);
    var cookieVal = Storage.get(regionCheck);

    // Check if currentdate is beyond the expiry date, or the expirydate is unset.
    if (cookieVal != 'seen') {
      setEventListeners($smartbanner);
      showSmartbanner(true);
    }
  }.bind(this);

  // Cookie/localstorage functions
  var setEventListeners = function ($smartbanner) {
    $smartbanner.querySelector('.generic-smartbanner__button--close').addEventListener('click', function () {
      setCookie();
      showSmartbanner(false);
    });

    $smartbanner.querySelector('.generic-smartbanner__cta')?.addEventListener('click', function () {
      if ($smartbanner.dataset['show-ios-banner']) {
        setCookie();
      }

      // Create a virtuel event when clicking on SmartBanner link
      Ensighten.pushGaEvent('smartBanner', 'click', 'lotto_app', null, false);
    });
  };

  var setCookie = function () {
    // Set the expiry date in the localstorage
    Storage.set(region + '-smartbanner', 'seen', 60 * 60 * 24 * 7);
  };

  var showSmartbanner = function (bool) {
    if (bool) {
      // Show smartbanner
      $staticSmartbannerFix?.classList.add('is-open');
      $smartbanner?.classList.add('is-open');

      // Create a virtuel event that SmartBanner is active
      Ensighten.pushGaEvent('smartBanner', 'visible', 'lotto_app', null, true);
    } else {
      $staticSmartbannerFix?.classList.remove('is-open');
      $smartbanner?.classList.remove('is-open');
    }
  };

  var init = function () {
    DOMUtils.ready(function () {
      if (document.querySelector('.generic-smartbanner')) {
        detect();
      }
    });
  };

  init();
});
